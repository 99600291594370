import { utils } from './utils';

(() => {
  const CONSTANTS: {
    mainEl: any;
    mainTabTriggers: any;
    mainTabPanels: any;
    mainModalTrigger: any;
    galleryModalTrigger: any;
    mainModalCloseTrigger: any;
    backToRealResultsCTA: any;
    galleryModalCloseCTA: any;
    galleryContent: any;
    galleryModal: any;
  } = {
    mainEl: null,
    mainTabTriggers: null,
    mainTabPanels: null,
    mainModalTrigger: null,
    galleryModalTrigger: null,
    mainModalCloseTrigger: null,
    backToRealResultsCTA: null,
    galleryModalCloseCTA: null,
    galleryContent: null,
    galleryModal: null,
  };

  let tabSwitchTimer;
  let tabReadyTimer;

  // callback after subtab is shown
  const showSubTabCallback = () => {
    if (tabReadyTimer) {
      clearTimeout(tabReadyTimer);
    }

    tabReadyTimer = setTimeout(() => {
      window.Bus.emit('lo3:hideLoader');
      clearTimeout(tabReadyTimer);
    }, 800);
  };

  // shows sub tab of the active tab
  const showSubTab = subTabVal => {
    if (!subTabVal) {
      showSubTabCallback();
      return;
    }

    let mainTabPanels = CONSTANTS.mainTabPanels;
    if (!mainTabPanels) {
      mainTabPanels = CONSTANTS.mainEl?.querySelectorAll(
        '.video-testimonials__main-tabs > .aaaem-tabs__tabpanels > .aaaem-tabs__tabpanel'
      );
      CONSTANTS.mainTabPanels = mainTabPanels;
    }

    if (mainTabPanels?.length) {
      let activePanel;
      if (mainTabPanels?.length) {
        mainTabPanels.forEach(mainPanel => {
          if (!mainPanel.getAttribute('hidden')) {
            activePanel = mainPanel;
          }
        });
      }

      if (activePanel) {
        const subPanelTriggers = activePanel?.querySelectorAll(
          '.aaaem-tabs__tablist a'
        );
        if (subPanelTriggers?.length) {
          let triggerFound = false;
          subPanelTriggers.forEach(trigger => {
            if (trigger.getAttribute('data-index') === subTabVal) {
              trigger?.click?.();
              const tabs = trigger.closest('.aaaem-tabs');
              tabs?._updateStyles?.();
              showSubTabCallback();
              triggerFound = true;
            }
          });

          if (triggerFound === false) {
            showSubTabCallback();
          }
        } else {
          showSubTabCallback();
        }
      } else {
        showSubTabCallback();
      }
    } else {
      showSubTabCallback();
    }
  };

  // shows tab content for a given mainTabVal and subTabVal
  const showTabContent = (
    mainTabVal = '1',
    subTabVal = '1',
    immediate = false
  ) => {
    if (tabSwitchTimer) {
      clearTimeout(tabSwitchTimer);
      tabSwitchTimer = null;
      window.Bus.emit('lo3:hideLoader');
    }

    if (immediate) {
      window.Bus.emit('lo3:hideLoader');
    }

    let mainTabTriggers = CONSTANTS.mainTabTriggers;
    if (!mainTabTriggers) {
      mainTabTriggers = CONSTANTS.mainEl?.querySelectorAll?.(
        '.video-testimonials__main-tabs > .aaaem-tabs__tablist a'
      );
      CONSTANTS.mainTabTriggers = mainTabTriggers;
    }

    if (CONSTANTS.mainTabTriggers?.length) {
      CONSTANTS.mainTabTriggers?.forEach(trigger => {
        if (mainTabVal === trigger.getAttribute('data-index')) {
          tabSwitchTimer = setTimeout(
            () => {
              trigger?.click?.();
              showSubTab(subTabVal);
              clearTimeout(tabSwitchTimer);
              tabSwitchTimer = null;
            },
            immediate ? 50 : 400
          );
        }
      });
    }
  };

  // appending a listener to show testimonial videos
  const appendBusListener = () => {
    window.Bus.on('lo3:showVideoTestimonials', params => {
      // opening the modal in the real results section
      const mainModalTrigger =
        CONSTANTS.mainModalTrigger ||
        (document.querySelector(
          '.real-results-modal-trigger'
        ) as HTMLButtonElement);
      CONSTANTS.mainModalTrigger = mainModalTrigger;
      mainModalTrigger.click?.();

      const galleryModalTrigger = document.querySelector(
        '.gallery__open-cta'
      ) as HTMLButtonElement;
      CONSTANTS.galleryModalTrigger = galleryModalTrigger;

      const mainTabVal = params?.mainTabVal;
      const subTabVal = params?.subTabVal;
      window.Bus.emit('lo3:showLoader');
      // if `.video-testimonials` element is already present, triggering showTabContent
      // else waiting for the Page Fragment `video-testimonials-pf` to load and then adding tab functionality and triggering showTabContent
      if (CONSTANTS.mainEl) {
        showTabContent(mainTabVal, subTabVal, true);
      } else {
        if (document.querySelector('#video-testimonials-pf')) {
          window.Bus.emit('emu-pf-video-testimonials-pf-load');
          window.Bus.on('emu-pf:loaded', ({ id }) => {
            if (id === 'video-testimonials-pf') {
              utils
                .waitForElement('.video-testimonials')
                .then(videoTestimonials => {
                  if (videoTestimonials) {
                    CONSTANTS.mainEl = videoTestimonials;
                    initVideoTestimonialTabs(videoTestimonials);
                    showTabContent(mainTabVal, subTabVal);
                    appendGalleryEvents(videoTestimonials);
                  }
                });
            }
          });
        } else {
          console.warn('Page Fragment for Video Testimonials not found');
          window.Bus.emit('lo3:hideLoader');
          // close button of the real results modal
          const mainModalCloseTrigger = document?.querySelector(
            '.real-results__modal .close.main-close'
          ) as HTMLButtonElement;

          mainModalCloseTrigger?.click?.();
        }
      }
    });
  };

  // appending events related to the real results gallery
  const appendGalleryEvents = el => {
    if (el) {
      // clicking `gallery__open-cta` button programmatically when gallery CTA is clicked.
      // This is needed because the buttons are loading via Page Fragment, and the default button functionality is not being appended.
      const galleryBtns = el.querySelectorAll(
        '.video-testimonials__gallery-cta'
      ) as NodeListOf<HTMLButtonElement>;
      if (galleryBtns?.length) {
        galleryBtns.forEach(btn => {
          btn.addEventListener('click', () => {
            showGalleryModal();
          });
        });
      }

      // close button of the real results modal
      const mainModalCloseTrigger =
        CONSTANTS.mainModalCloseTrigger ||
        (el
          ?.closest('.real-results__modal')
          ?.querySelector('.close.main-close') as HTMLButtonElement);
      CONSTANTS.mainModalCloseTrigger = mainModalCloseTrigger;
    }
  };

  // appending events for the CTAs inside the gallery modal
  const appendGalleryButtonEvents = el => {
    const galleryModal =
      CONSTANTS.galleryModal || el?.closest('.gallery__modal');
    CONSTANTS.galleryModal = galleryModal;

    const galleryModalCloseCTA =
      CONSTANTS.galleryModalCloseCTA ||
      galleryModal?.querySelector('.close.main-close');
    CONSTANTS.galleryModalCloseCTA = galleryModalCloseCTA;

    const backToRealResultsCTA =
      CONSTANTS.backToRealResultsCTA ||
      (galleryModal?.querySelector('#gallery-close-cta') as HTMLButtonElement);
    CONSTANTS.backToRealResultsCTA = backToRealResultsCTA;
    if (backToRealResultsCTA) {
      backToRealResultsCTA.addEventListener('click', () => {
        galleryModalCloseCTA?.click?.();
      });
    }
  };

  // shows gallery modal
  const showGalleryModal = () => {
    const galleryModalTrigger =
      CONSTANTS.galleryModalTrigger ||
      (document.querySelector('.gallery__open-cta') as HTMLButtonElement);

    // closing real results modal and opening gallery modal
    CONSTANTS.mainModalCloseTrigger?.click?.();
    galleryModalTrigger?.click?.();
    window.Bus.emit('lo3:showLoader');
    const gallery =
      CONSTANTS.galleryContent || document.querySelector('.gallery');
    if (gallery) {
      window.Bus.emit('lo3:hideLoader');
      CONSTANTS.galleryContent = gallery;
    } else {
      window.Bus.emit('emu-pf-gallery-pf-load');
      window.Bus.on('emu-pf:loaded', ({ id }) => {
        if (id === 'gallery-pf') {
          utils.waitForElement('.gallery').then(gallery => {
            CONSTANTS.galleryContent = gallery;
            appendGalleryButtonEvents(gallery);
            window.Bus.emit('lo3:hideLoader');
          });
        }
      });
    }
  };

  // adding tabs related functionality to tabs present in the mainEl
  const initVideoTestimonialTabs = mainEl => {
    const mainTabs = mainEl.querySelector('.aaaem-tabs');
    const tabs = mainTabs?.querySelectorAll('.aaaem-tabs');

    utils.initTabsFn(mainTabs);
    tabs.forEach(tab => {
      utils.initTabsFn(tab);
    });
  };

  const init = () => {
    const videoTestimonials = document.querySelector('.video-testimonials');

    if (videoTestimonials) {
      // @ts-ignore
      CONSTANTS.mainEl = videoTestimonials;
      initVideoTestimonialTabs(videoTestimonials);
      appendGalleryEvents(videoTestimonials);
    }

    // listens to showVideoTestimonials event and shows content accordingly
    if (window.Bus) {
      appendBusListener();
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
