// This file handles specific events that should happen when a specific screen gets active/inactive
// Also handles global actions when screens become active and inactive
import { scrollUtils } from './scroll-utils';
import { utils } from './utils';

(() => {
  // in Real Stories section,
  // when user scrolls down, the image must hide revealing the content, and show side nav, and change color of the floating CTAs.
  // when user scrolls up, the content must hide and the image should build to show fullscreen, and hide side nav, and change color of the floating CTAs
  const handleRealStoriesEntry = e => {
    const contentBox = e.section?.querySelector('.content-box');

    // showing side nav, changing color of floating CTA, revealing content
    const makeContentBoxVisible = () => {
      window.Bus.emit('lo3:showSideNav');
      window.Bus.emit('lo3:changeFloatingCTAToDark');
      contentBox?.classList.add('content-box--active');
      e.section?.classList.add('content-visible');
    };

    // hiding side nav, changing color of floating CTA, revealing image to full screen
    const makeContentBoxHidden = () => {
      window.Bus.emit('lo3:hideSideNav');
      contentBox?.classList.remove('content-box--active');
      e.section?.classList.remove('content-visible');

      if (window.innerWidth >= 1024) {
        window.Bus.emit('lo3:changeFloatingCTAToLight');
      } else {
        window.Bus.emit('lo3:changeFloatingCTAToDark');
      }
    };

    if (scrollUtils.scrollDir === 'up') {
      makeContentBoxVisible();
    } else {
      makeContentBoxHidden();
    }

    window.Bus.on('lo3:proxyScrollSectionActivated', () => {
      makeContentBoxVisible();
    });

    window.Bus.on('lo3:proxyScrollSectionDeactivated', () => {
      makeContentBoxHidden();
    });
  };

  const handleRealStoriesExit = () => {
    window.Bus.off('lo3:proxyScrollSectionActivated');
    window.Bus.off('lo3:proxyScrollSectionDeactivated');
  };

  const handleAutoplayVideos = e => {
    if (e?.section?.classList.contains(scrollUtils.hasAutoplayVideoClass)) {
      const embeds = e.section.querySelectorAll('.emu-embed');

      if (embeds?.length) {
        embeds.forEach(embed => {
          if (embed.id) {
            if (embed.closest('.desktop-view')) {
              if (window.innerWidth >= 1024) {
                utils.playVideo(embed, true);
              }
            } else if (embed.closest('.tablet-mobile-view')) {
              if (window.innerWidth < 1024) {
                utils.playVideo(embed, true);
              }
            } else {
              utils.playVideo(embed, true);
            }
          }
        });
      }
    }
  };

  const handlePauseVideos = e => {
    utils.pauseEmbedVideos(e);
    utils.hideTooltips(e.section);
  };

  // initialization
  const initScreenManager = () => {
    if (window.Bus) {
      window.Bus.on('lo3:sectionActivated', e => {
        window.Bus.off('lo3:proxyScrollSectionActivated');
        window.Bus.off('lo3:proxyScrollSectionDeactivated');
        handleAutoplayVideos(e);

        if (e.id === 'real-stories-screen-2') {
          handleRealStoriesEntry(e);
        } else if (e.id === 'first-look-screen-8') {
          utils.playLottieAnimation('10-15-minutes');
        } else if (e.id === 'first-look-screen-9') {
          utils.playLottieAnimation('1-2-days');
        } else if (e.id === 'first-look-screen-10') {
          utils.playLottieAnimation('4-months');
        }
      });

      window.Bus.on('lo3:sectionDeactivated', e => {
        handlePauseVideos(e);

        if (e.id === 'real-stories-screen-2') {
          handleRealStoriesExit();
        } else if (e.id === 'start-journey-screen-4') {
          utils.hideDatepicker('plan-your-journey-datepicker');
        }
      });
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', initScreenManager);
  } else {
    initScreenManager();
  }
})();
