import { utils } from './utils';

export const scrollUtils = {
  activeSectionContainerClass: 'lo3-section-container--active',
  prevActiveSectionContainerClass: 'lo3-section-container--prev-active',
  sectionClass: 'lo3-section',
  sectionContainerAEMClass: 'container',
  sectionContainerClass: 'lo3-section-container',
  sectionWrapperClass: 'lo3-section-wrapper',
  lastAnimatedSectionClass: 'animated-last',
  sectionDarkBgClass: 'lo3-section--dark-bg',
  sectionLightBgClass: 'lo3-section--light-bg',
  sectionFloatingCTADesktopLightClass:
    'lo3-section--light-floating-cta-desktop',
  sectionFloatingCTADesktopDarkClass: 'lo3-section--dark-floating-cta-desktop',
  sectionFloatingCTAMobileLightClass: 'lo3-section--light-floating-cta-mobile',
  sectionFloatingCTAMobileDarkClass: 'lo3-section--dark-floating-cta-mobile',
  sectionHideSideNavClass: 'lo3-section--hide-side-nav',
  sectionShowMainNavClass: 'lo3-section--show-main-nav',
  sectionShowTreatmentCTAClass: 'lo3-section--show-treatment-cta',
  sectionHideFloatingCTAClass: 'lo3-section--hide-floating-cta',
  sectionHideJourneyCTAClass: 'lo3-section--hide-journey-cta',
  hasAnimatedSubsectionClass: 'lo3-section--has-animated-subsection',
  hasAutoplayVideoClass: 'lo3-section--has-autoplay-video',
  animatedSubsectionClass: 'lo3-animated-subsection',
  animatedSubsectionImmediateShowClass:
    'lo3-animated-subsection--show-immediate',
  animatedSubsectionActiveClass: 'lo3-animated-subsection--active',
  animatedSubsectionPrevActiveClass: 'lo3-animated-subsection--prev-active',
  hasHighlightedText: 'has-highlighted-text',
  allSectionWrappers: null,
  activationTimer: null,
  scrollPaused: false,
  snapScrollOn: false,
  scrollDir: 'down',
  sectionSwitchInProgress: false,
  skipAnimationLastWaiting: false,
  curScrollDir: 'down',
  snapScrollBodyClass: 'snap-scroll-on',
  sectionHoopsClass: 'lo3-section--has-hoops',

  // returns the current active lo3-section-container
  getCurActiveSectionContainer() {
    return document.querySelector(
      `.${this.sectionContainerClass}.${this.activeSectionContainerClass}`
    );
  },

  // de-activates the current active section
  deactivateSectionContainer() {
    const curActiveSectionContainer = this.getCurActiveSectionContainer();
    const curSection = curActiveSectionContainer?.querySelector(
      `.${this.sectionClass}`
    );
    const curSecContainerId = curSection?.getAttribute?.('id');
    curActiveSectionContainer?.classList?.remove(
      this.activeSectionContainerClass,
      this.prevActiveSectionContainerClass
    );

    if (curSecContainerId) {
      window.Bus?.emit('lo3:sectionDeactivated', {
        id: curSecContainerId,
        sectionContainer: curActiveSectionContainer,
        section: curSection,
      });
    }
  },

  // callback when next active section is not found.
  // scrolls to the top or bottom part of the available active section
  nextSectionNotFoundCallback(dir) {
    if (
      (dir === 'up' && window.scrollY < 200) ||
      (dir === 'down' &&
        Math.floor(window.scrollY) ===
          Math.floor(document.body.scrollHeight - window.innerHeight))
    ) {
      this.sectionSwitchInProgress = false;
      return;
    }

    this.sectionSwitchInProgress = true;
    const curActiveSection =
      scrollUtils.getCurActiveSectionContainer() as HTMLElement;
    let entity = dir;
    if (curActiveSection) {
      entity =
        dir === 'down'
          ? curActiveSection.offsetTop + curActiveSection.offsetHeight
          : curActiveSection.offsetTop - curActiveSection.offsetHeight;
    }

    this.pauseScroll();
    this.deactivateSectionContainer();
    utils.scrollToEl(entity, true).then(() => {
      setTimeout(() => {
        this.isiInViewCallback();
        this.resumeScroll();
        this.sectionSwitchInProgress = false;
      }, 400);
    });
  },

  // when ISI is in view, disable the snap scroll and wait for the user to scroll up, so as to enable the snap scroll back again
  isiInViewCallback() {
    window.Bus.emit('lo3:hideMainMenu');
    window.Bus.emit('lo3:hideSideNav');
    this.disableSnapScroll();
    this.deactivateSectionContainer();
    if (document.body.classList.contains(this.snapScrollBodyClass)) {
      this.getReadyToEnableSnapScroll();
    }
  },

  // waits for the user to scroll up, disables snap scroll, then scrolls to and makes the last available section as active section
  getReadyToEnableSnapScroll() {
    const sectionWrappers = document.querySelectorAll(
      `.${this.sectionContainerClass}`
    ) as NodeListOf<HTMLElement>;
    let lastSectionWrapper;
    let scrollPos = window.scrollY;
    if (sectionWrappers?.length) {
      lastSectionWrapper = sectionWrappers[sectionWrappers.length - 1];
    }
    if (lastSectionWrapper) {
      scrollPos =
        lastSectionWrapper.offsetTop + lastSectionWrapper.offsetHeight;
    }

    const scrollCallback = () => {
      if (this.scrollDir === 'up' && window.scrollY <= scrollPos) {
        window.removeEventListener('scroll', scrollCallback);
        this.pauseScroll();
        this.enableSnapScroll();
        utils.scrollToEl(lastSectionWrapper, true).then(() => {
          this.makeSectionContainerActive(lastSectionWrapper);
          this.pauseScroll();
          queueMicrotask(() => {
            this.resumeScroll();
          });
          window.removeEventListener('scroll', scrollCallback);
        });
      }
    };

    window.addEventListener('scroll', scrollCallback);
  },

  scrollToNextSection(dir) {
    if (this.scrollPaused) {
      window.Bus?.emit('lo3:proxyScroll', {
        dir,
      });
    } else {
      if (this.sectionSwitchInProgress) return;

      this.getNextSectionContainer(dir)
        .then(
          nextContainer => {
            if (nextContainer instanceof HTMLElement) {
              this.enableSnapScroll();
              if (this.snapScrollOn) {
                utils.scrollToEl(nextContainer).then(() => {
                  this.sectionSwitchInProgress = false;
                });
              } else {
                this.sectionSwitchInProgress = false;
              }
            } else {
              this.nextSectionNotFoundCallback(dir);
            }
          },
          () => {
            this.nextSectionNotFoundCallback(dir);
          }
        )
        .catch(e => {
          console.warn(e);
          this.nextSectionNotFoundCallback(dir);
        });
    }
  },

  /**
   * For a given section, returns the last animated element.
   * @param sectionContainer
   * @returns HTMLElement present in the section, that has the last-animated class
   */
  getLastAnimatedSection(sectionContainer) {
    if (sectionContainer) {
      const lastAnimatedEl = sectionContainer.querySelector(
        `.${this.lastAnimatedSectionClass}`
      );
      return lastAnimatedEl;
    }
  },

  // emits bus events based on classes present for the sections
  handleSectionBusEvents(sectionContainer?) {
    if (utils.isAuthorMode) return;
    const curSectionContainer =
      sectionContainer || this.getCurActiveSectionContainer();
    const section = curSectionContainer?.querySelector(`.${this.sectionClass}`);
    if (section) {
      // controlling the body background
      if (section.classList.contains(this.sectionDarkBgClass)) {
        window.Bus?.emit('lo3:changeBgToDark');
      } else {
        window.Bus?.emit('lo3:changeBgToLight');
      }

      //controlling moving hoops in the background
      if (section.classList.contains(this.sectionHoopsClass)) {
        window.Bus?.emit('lo3:showHoops');
      } else {
        window.Bus?.emit('lo3:hideHoops');
      }

      // controlling the floating cta color for desktop and mobile
      if (window.innerWidth >= 1024) {
        if (
          section.classList.contains(this.sectionFloatingCTADesktopLightClass)
        ) {
          window.Bus?.emit('lo3:changeFloatingCTAToLight');
        } else {
          window.Bus?.emit('lo3:changeFloatingCTAToDark');
        }
      } else {
        if (
          section.classList.contains(this.sectionFloatingCTAMobileLightClass)
        ) {
          window.Bus?.emit('lo3:changeFloatingCTAToLight');
        } else {
          window.Bus?.emit('lo3:changeFloatingCTAToDark');
        }
      }

      // controlling to show/hide the side nav
      if (section.classList.contains(this.sectionHideSideNavClass)) {
        window.Bus?.emit('lo3:hideSideNav');
      } else {
        window.Bus?.emit('lo3:showSideNav');
      }

      if (section.classList.contains(this.sectionShowMainNavClass)) {
        window.Bus?.emit('lo3:showMainMenu');
      } else {
        window.Bus?.emit('lo3:hideMainMenu');
      }

      if (section.classList.contains(this.sectionShowTreatmentCTAClass)) {
        window.Bus?.emit('lo3:showGlobalTreatmentCTA');
      } else {
        window.Bus?.emit('lo3:hideGlobalTreatmentCTA');
      }

      if (section.classList.contains(this.sectionHideFloatingCTAClass)) {
        window.Bus?.emit('lo3:hideFloatingCTA');
      } else {
        window.Bus?.emit('lo3:showFloatingCTA');
      }

      if (section.classList.contains(this.sectionHideJourneyCTAClass)) {
        window.Bus?.emit('lo3:hideJourneyCTA');
      } else {
        window.Bus?.emit('lo3:showJourneyCTA');
      }
    }
  },

  /**
   * Activates a sectionContainer, and deactivates another sectionContainer
   * Controls the background color of the body
   * @param nextSectionContainer - sectionContainer that should be made active
   * @param curActiveSectionContainer sectionContainer that should be made inactive, and to be labeled as previously active
   */
  activateSectionContainer(nextSectionContainer, curActiveSectionContainer?) {
    if (
      !nextSectionContainer ||
      nextSectionContainer?.classList.contains(this.activeSectionContainerClass)
    ) {
      this.sectionSwitchInProgress = false;
      this.handleSectionBusEvents(nextSectionContainer);
      return;
    }

    // remove active class to the cur-active section
    curActiveSectionContainer?.classList?.remove(
      this.activeSectionContainerClass
    );

    // removing any previously active sections
    document
      .querySelector(`.${this.activeSectionContainerClass}`)
      ?.classList.remove(this.activeSectionContainerClass);

    const section = nextSectionContainer?.querySelector(
      `.${this.sectionClass}`
    );
    const id = section?.getAttribute?.('id');
    const curSection = curActiveSectionContainer?.querySelector(
      `.${this.sectionClass}`
    );
    const curSecContainerId = curSection?.getAttribute?.('id');

    // when the transition of the section is done, remove the prev active class
    utils.transitionend(section, () => {
      curActiveSectionContainer?.classList?.remove(
        this.prevActiveSectionContainerClass
      );

      if (id) {
        window.Bus?.emit('lo3:sectionActivated', {
          id,
          sectionContainer: nextSectionContainer,
          section,
        });

        if (curSecContainerId) {
          window.Bus?.emit('lo3:sectionDeactivated', {
            id: curSecContainerId,
            sectionContainer: curActiveSectionContainer,
            section: curSection,
          });
        }
      }
    });

    nextSectionContainer.classList.remove(this.prevActiveSectionContainerClass);
    nextSectionContainer.classList.add(this.activeSectionContainerClass);

    this.resumeScroll();
    this.handleSectionBusEvents(nextSectionContainer);

    window.Bus.emit('lo3:enableSnapScroll');
    window.Bus.emit('lo3:updateMenuState');
    this.sectionSwitchInProgress = false;
    this.skipAnimationLastWaiting = false;
  },

  // hides all animated subsections
  hideAllAnimatedSubsections() {
    const animatedSubsections = document?.querySelectorAll(
      `.${this.animatedSubsectionActiveClass}`
    ) as NodeListOf<HTMLElement>;

    if (animatedSubsections?.length) {
      animatedSubsections.forEach(el => {
        el.classList.remove(this.animatedSubsectionActiveClass);
        el.style.height = '';
      });
    }
  },

  /**
   * Makes a lo3-section-container active
   * @param sectionContainer - HTML Element with class lo3-section-container.
   */
  makeSectionContainerActive(sectionContainer) {
    if (sectionContainer) {
      if (
        sectionContainer.classList.contains(this.activeSectionContainerClass)
      ) {
        this.sectionSwitchInProgress = false;
        this.handleSectionBusEvents(sectionContainer);
        return;
      }

      // making sure that the section container is fully visible on the screen
      if (sectionContainer.offsetTop !== window.scrollY && this.snapScrollOn) {
        utils.scrollToEl(sectionContainer);
      }

      this.sectionSwitchInProgress = true;
      const curActiveSectionContainer = this.getCurActiveSectionContainer();

      if (curActiveSectionContainer) {
        let waitForAnimationEl: HTMLElement;
        const lastAnimatedSection = this.getLastAnimatedSection(
          curActiveSectionContainer
        );

        const section = curActiveSectionContainer.querySelector(
          `.${this.sectionClass}`
        ) as HTMLElement;
        waitForAnimationEl = lastAnimatedSection || section;

        // don't wait for the section animation if skipAnimationLastWaiting is set to true
        // usually skipAnimationLastWaiting is set to true when user clicks on menu item in side nav
        const isSectionVisible =
          waitForAnimationEl?.getBoundingClientRect().top;
        if (
          (isSectionVisible || isSectionVisible === 0) &&
          this.skipAnimationLastWaiting !== true
        ) {
          curActiveSectionContainer?.classList?.remove(
            `${this.prevActiveSectionContainerClass}-up`,
            `${this.prevActiveSectionContainerClass}-down`
          );

          utils.transitionend(waitForAnimationEl, () => {
            this.activateSectionContainer(
              sectionContainer,
              curActiveSectionContainer
            );
          });

          curActiveSectionContainer.classList.add(
            this.prevActiveSectionContainerClass,
            `${this.prevActiveSectionContainerClass}-${this.scrollDir}`
          );
        } else {
          curActiveSectionContainer.classList.add(
            this.prevActiveSectionContainerClass
          );

          this.activateSectionContainer(
            sectionContainer,
            curActiveSectionContainer
          );
        }
      } else {
        this.activateSectionContainer(sectionContainer);
        this.sectionSwitchInProgress = false;
      }

      this.activationTimer = null;
    }
  },

  // Pause the page scroll
  pauseScroll() {
    this.scrollPaused = true;
  },

  // Resume the page scroll
  resumeScroll() {
    this.scrollPaused = false;
    window.Bus.off('lo3:proxyScroll');
  },

  // disables snap scroll temporarily
  disableSnapScroll() {
    this.snapScrollOn = false;
  },

  // enables snap scroll
  enableSnapScroll() {
    this.snapScrollOn = document.body.classList.contains(
      this.snapScrollBodyClass
    );
  },

  // enables snap scroll forcefully
  forceEnableSnapScroll() {
    document.body.classList.add(this.snapScrollBodyClass);
    this.snapScrollOn = true;
  },

  // disables snap scroll forcefully
  forceDisableSnapScroll() {
    document.body.classList.remove(this.snapScrollBodyClass);
    this.snapScrollOn = false;
  },

  // returns next active lo3-section-container based on scroll direction
  getNextSectionContainer(dir: 'up' | 'down') {
    return new Promise((res, rej) => {
      const curActiveSectionContainer = this.getCurActiveSectionContainer();

      let nextSectionContainer =
        dir === 'down'
          ? curActiveSectionContainer?.nextElementSibling
          : curActiveSectionContainer?.previousElementSibling;
      if (
        !nextSectionContainer?.classList.contains(this.sectionContainerClass)
      ) {
        nextSectionContainer = null;
      }

      if (nextSectionContainer) {
        res(nextSectionContainer);
      } else {
        const parent = curActiveSectionContainer?.closest(
          `.${this.sectionWrapperClass}`
        );

        //@ts-ignore
        if (parent && this.allSectionWrappers?.length) {
          let nextIndex;
          const curId = parent?.getAttribute('id');

          //@ts-ignore
          this.allSectionWrappers?.forEach((el, i) => {
            if (!nextIndex && el.getAttribute('id') === curId) {
              nextIndex = dir === 'down' ? i + 1 : i - 1;
            }
          });

          if (
            (nextIndex || nextIndex === 0) &&
            this.allSectionWrappers[nextIndex]
          ) {
            const nextSectionWrapper = this.allSectionWrappers[
              nextIndex
            ] as HTMLElement;

            utils
              .waitForElement(
                `.${this.sectionContainerClass}`,
                nextSectionContainer
              )
              .then(() => {
                let nextSectionContainer = nextSectionWrapper?.querySelector(
                  `.${this.sectionContainerClass}`
                );

                if (dir === 'up') {
                  // @ts-ignore
                  const allSectionContainers =
                    nextSectionWrapper?.querySelectorAll(
                      `.${this.sectionContainerClass}`
                    );
                  nextSectionContainer =
                    allSectionContainers[allSectionContainers.length - 1];
                }

                if (nextSectionContainer) {
                  res(nextSectionContainer);
                } else {
                  res(nextSectionWrapper);
                }
              });
          } else {
            rej();
          }
        } else {
          rej();
        }
      }
    });
  },
};

// @ts-ignore
window.scrollUtils = scrollUtils;
