// This file adds vanillajs-datepicker to an input added inside a container with class datepicker-container
// To get a date picker, add a container with a class name datepicker-container. Inside the container add a form-text component
// To the container if an ID is authored, window._datepickerInstance[id] will contain the datepicker instance for further changes if needed

//@ts-ignore
import { Datepicker } from 'vanillajs-datepicker';

// adds a class to the days that are single digit. Needed as per the design
const addZero = e => {
  const picker = e?.detail?.datepicker?.picker?.main;
  const allDates = picker?.querySelectorAll('.datepicker-cell.day');

  if (allDates?.length) {
    allDates.forEach(date => {
      let curDate = date.textContent;
      const dateCount = parseInt(curDate);
      if (dateCount <= 9) {
        curDate = '0' + dateCount;
      }
      date.innerHTML = `<span>${curDate}</span>`;
    });
  }
};

// calculates the datepicker dropdown top value
const positionDatepicker = (e, inpElement, datepicker) => {
  const coords = inpElement.getBoundingClientRect();
  const datepickerElement = datepicker.picker.element;
  const datepickerTop = coords.top + coords.height;
  datepickerElement.style.top = `${datepickerTop}px`;
}

// adding a class to the input when date is selected
const dateSelected = e => {
  e?.target?.classList.toggle('datepicker-input--has-value', e?.detail?.date);
};

const initiateDatepicker = el => {
  const inpElem = el?.querySelector('input');
  if (inpElem) {
    const datepicker = new Datepicker(inpElem, {
      container: document.body,
      autohide: true,
      minDate: new Date(),
      // to override the default keys (arrow down/escape) which show/toggle the datepicker
      shortcutKeys: {
        show: null,
        toggle: null,
      }
    });

    // add zero to all single digit dates
    inpElem.addEventListener('show', addZero);
    inpElem.addEventListener('changeMonth', addZero);
    inpElem.addEventListener('changeView', addZero);
    inpElem.addEventListener('changeDate', dateSelected);
    inpElem.addEventListener('show', e => {
      positionDatepicker(e, inpElem, datepicker);
    });
    // to maintain the datepicker top value on window resize
    window.addEventListener('resize', e => {
      positionDatepicker(e, inpElem, datepicker);
    });

    // appending the datepicker variable to window datepickerInstance
    const id = el.id;
    if (id && datepicker) {
      window._datepickerInstance[id] = datepicker;
    }
  }
};

window._datepickerInstance = window._datepickerInstance || {};
const initDatepickers = (parent?) => {
  const root = parent || document;
  if (root) {
    const datepickers = root.querySelectorAll('.datepicker-container');
    if (datepickers?.length) {
      datepickers.forEach(datepicker => {
        if (!datepicker.classList.contains('datepicker-container--processed')) {
          datepicker.classList.add('datepicker-container--processed');
          initiateDatepicker(datepicker);
        }
      });
    }
  }
};

export default initDatepickers;
