// Stylesheets
import './main.scss';

// Imports all component based scss files.
import './components/**/*.scss';

// import favicon here.
import './resources/images/favicon.svg';

import './scroll-manager';
import './menu';
import './proxy-scroll';
import './screen-manager';
import { scrollUtils } from './scroll-utils';
import { utils } from './utils';
import './components/datepicker/datepicker';
import './plan-your-journey';
import './real-results';
import './video-testimonial-tabs';

declare global {
  interface Window {
    Bus: any; // event bus
    OneTrust: any;
    _tnsInstances: any; // carousel instances
    _tabbyInstances: any; // tabset instances
    _plyrInstances: any;
    _ssSelectInstance: any; // select instances
    _datepickerInstance: any;
    _lottieInstances: any;
  }
}

const CONSTANTS = {
  bodyDarkClass: 'bg--dark',
  bodyLightClass: 'bg--light',
  floatingCTALightClass: 'has-floating-cta-light',
  floatingCTADarkClass: 'has-floating-cta-dark',
  sideNavHiddenClass: 'has-side-nav--hidden',
  mainMenuHiddenClass: 'has-main-menu--hidden',
  fullscreenClass: 'is-fullscreen-mode',
  hideGlobalTreatmentCTAClass: 'has-treatment-cta--hidden',
  hideFloatingCTAClass: 'has-floating-cta--hidden',
  hideJourneyCTAClass: 'has-journey-cta--hidden',
  showHoopsClass: 'has-hoops',
  mainTabVal: 1,
  subTabVal: 1,
  galleryModalISIClicked: false,
};

// Loader show and hide
const appendLoaderEvents = () => {
  const loader = document.querySelector('.loader__wrapper');
  if (loader && window.Bus) {
    window.Bus.on('lo3:showLoader', () => {
      scrollUtils.pauseScroll();
      document.body.classList.add('has--loader');
      window?._lottieInstances?.loader?.play?.();
    });

    window.Bus.on('lo3:hideLoader', () => {
      scrollUtils.resumeScroll();
      document.body.classList.remove('has--loader');
      window?._lottieInstances?.loader?.pause?.();
    });

    // making sure that the loader shows only in home page in publish mode on page load
    // Will be hidden via scroll manager when the window is loaded
    const isAuthorMode =
      utils?.isAuthorMode ||
      (window?.parent || window)?.location.href.indexOf('/editor.html/') >= 0;
    if (document.body.classList.contains('home') && isAuthorMode !== true) {
      window.Bus.emit('lo3:showLoader');
    } else {
      window.Bus.emit('lo3:hideLoader');
    }
  }
};

(() => {
  // shows random video from testimonials in real results screen
  const showRandomVideoTestimonials = () => {
    const carouselSlides = document.querySelectorAll('.testimonial-slide');
    const randomSlideNum = Math.ceil(Math.random() * carouselSlides.length);
    const randomSlide = carouselSlides[randomSlideNum] || carouselSlides[0];
    window.Bus.emit('lo3:showRandomVideoTestimonials', randomSlide);
  };

  // triggers random video from testimonials in real results screen
  const seeTestimonials = () => {
    window.Bus.emit('lo3:showLoader');
    const realResultsSection = document.querySelector('#real-results-screen-3');
    if (realResultsSection) {
      showRandomVideoTestimonials();
    } else {
      window.Bus.emit('emu-pf-real-results-load');
      window.Bus.on('lo3:pf-loaded', ({ id }) => {
        if (id === 'real-results') {
          utils.waitForElement('#real-results').then(() => {
            showRandomVideoTestimonials();
          });
        }
      });
    }
  };

  // for all the links which has #testimonials, show testimonials
  const addTestimonialsLinkClick = (parent?) => {
    const parentEl = parent || document;
    // for all anchor tags with href #testimonials, show random testimonial video
    const anchors = parentEl.querySelectorAll('a[href="#testimonials"]');
    if (anchors?.length) {
      anchors.forEach(anchor => {
        if (!anchor.classList.contains('testimonials-link')) {
          anchor.classList.add('testimonials-link');
          anchor.addEventListener('click', e => {
            e.preventDefault();
            seeTestimonials();
          });
        }
      });
    }
  };

  const init = () => {
    if (window.Bus) {
      // Listeners for changing the body background between light and dark
      const bodyElement = document.body;
      window.Bus.on('lo3:changeBgToLight', () => {
        bodyElement?.classList.add(`${CONSTANTS.bodyLightClass}`);
        bodyElement?.classList.remove(`${CONSTANTS.bodyDarkClass}`);
      });

      window.Bus.on('emu-button:click', ({ id }) => {
        // when see stories floating cta is clicked, scroll to the results section and show a random video testimonial
        if (id === 'see-stories-button') {
          seeTestimonials();
        }
      });

      // changes the color of the body bg to dark gradient
      window.Bus.on('lo3:changeBgToDark', () => {
        if (utils.isAuthorMode) {
          return;
        }
        bodyElement?.classList.add(`${CONSTANTS.bodyDarkClass}`);
        bodyElement?.classList.remove(`${CONSTANTS.bodyLightClass}`);
      });

      //show moving hoops in background
      window.Bus.on('lo3:showHoops', () => {
        bodyElement?.classList.add(`${CONSTANTS.showHoopsClass}`);
      });

      //hide moving hoops in background
      window.Bus.on('lo3:hideHoops', () => {
        bodyElement?.classList.remove(`${CONSTANTS.showHoopsClass}`);
      });

      // changes floating cta color to white
      window.Bus.on('lo3:changeFloatingCTAToLight', () => {
        bodyElement?.classList.add(`${CONSTANTS.floatingCTALightClass}`);
        bodyElement?.classList.remove(`${CONSTANTS.floatingCTADarkClass}`);
      });

      // changes floating cta color to dark purple
      window.Bus.on('lo3:changeFloatingCTAToDark', () => {
        bodyElement?.classList.add(`${CONSTANTS.floatingCTADarkClass}`);
        bodyElement?.classList.remove(`${CONSTANTS.floatingCTALightClass}`);
      });

      // hides the desktop side nav
      window.Bus.on('lo3:hideSideNav', () => {
        bodyElement?.classList.add(`${CONSTANTS.sideNavHiddenClass}`);
      });

      // shows the desktop side nav
      window.Bus.on('lo3:showSideNav', () => {
        bodyElement?.classList.remove(`${CONSTANTS.sideNavHiddenClass}`);
      });

      // hides the desktop main menu
      window.Bus.on('lo3:hideMainMenu', () => {
        bodyElement?.classList.add(`${CONSTANTS.mainMenuHiddenClass}`);
      });

      // shows the desktop main menu
      window.Bus.on('lo3:showMainMenu', () => {
        bodyElement?.classList.remove(`${CONSTANTS.mainMenuHiddenClass}`);
      });

      // adds class to the body when full screen is needed
      window.Bus.on('lo3:fullscreenModeOn', () => {
        bodyElement?.classList.add(`${CONSTANTS.fullscreenClass}`);
      });

      // removes class from the body when full screen is not needed
      window.Bus.on('lo3:fullscreenModeOff', () => {
        bodyElement?.classList.remove(`${CONSTANTS.fullscreenClass}`);
      });

      // adds class to the body to hide the Global Treatment CTA
      window.Bus.on('lo3:hideGlobalTreatmentCTA', () => {
        bodyElement?.classList.add(`${CONSTANTS.hideGlobalTreatmentCTAClass}`);
      });

      // removes class from the body to show the Global Treatment CTA
      window.Bus.on('lo3:showGlobalTreatmentCTA', () => {
        bodyElement?.classList.remove(
          `${CONSTANTS.hideGlobalTreatmentCTAClass}`
        );
      });

      // adds class to the body to hide the Global Treatment CTA
      window.Bus.on('lo3:hideFloatingCTA', () => {
        bodyElement?.classList.add(`${CONSTANTS.hideFloatingCTAClass}`);
      });

      // removes class from the body to show the Global Treatment CTA
      window.Bus.on('lo3:showFloatingCTA', () => {
        bodyElement?.classList.remove(`${CONSTANTS.hideFloatingCTAClass}`);
      });

      // adds class to the body to hide the Start Journey CTA
      window.Bus.on('lo3:hideJourneyCTA', () => {
        bodyElement?.classList.add(`${CONSTANTS.hideJourneyCTAClass}`);
      });

      // removes class from the body to show the Start Journey CTA
      window.Bus.on('lo3:showJourneyCTA', () => {
        bodyElement?.classList.remove(`${CONSTANTS.hideJourneyCTAClass}`);
      });

      // pause scroll when modal is open, and add fullscreen mode
      window.Bus.on('emu-modal:open', ({ id }) => {
        const modalEl = document.querySelector(`[data-id="${id}"]`);
        scrollUtils.disableSnapScroll();

        if (modalEl?.classList.contains('modal--hide-background-ctas')) {
          window.Bus?.emit('lo3:fullscreenModeOn');
        }

        if (modalEl?.classList.contains('modal--show-isi-on-top')) {
          window.Bus?.emit('lo3:showISIOnTop');
        }
      });

      // resume scroll when modal is closed, and exit full screen mode, pause any playing videos if there are any inside the modal
      window.Bus.on('emu-modal:close', ({ id }) => {
        scrollUtils.resumeScroll();
        scrollUtils.enableSnapScroll();
        window.Bus?.emit('lo3:resetISIOnTop');
        window.Bus?.emit('lo3:fullscreenModeOff');

        const modalEl = document.querySelector(`[data-id="${id}"]`);
        modalEl?.querySelector('.modal-wrapper')?.scrollTo(0, 0);
        const closestSection = modalEl?.closest(
          `.${scrollUtils.sectionWrapperClass}`
        );
        if (closestSection) {
          utils.pauseEmbedVideos({ section: closestSection });
        }

        if (id === 'real-results-modal') {
          utils.pauseEmbedVideos({ section: modalEl });

          // when the real results modal is closed, get the currently active tab values
          [CONSTANTS.mainTabVal, CONSTANTS.subTabVal] =
            utils.getActiveTabs(modalEl);
        }

        // when gallery modal is closed, show the video testimonials modal(real results modal) back again
        if (id === 'gallery-modal' && !CONSTANTS.galleryModalISIClicked) {
          window.Bus.emit('lo3:showVideoTestimonials', {
            mainTabVal: CONSTANTS.mainTabVal,
            subTabVal: CONSTANTS.subTabVal,
          });
        }

        if (CONSTANTS.galleryModalISIClicked) {
          CONSTANTS.galleryModalISIClicked = false;
        }
      });

      // cannot rely on the default scroll functionality of ISI given by AAAEM, as the scrolling is too much customized
      // Added a button and added click events to it
      // exit the full screen mode when isi expand is clicked
      // close any open modals if isi expand is clicked
      window.Bus.on('emu-button:click', ({ id }) => {
        if (id === 'expand-isi') {
          window.Bus?.emit('lo3:fullscreenModeOff');

          window.Bus?.emit('lo3:hideFloatingCTA');
          // scrolling to the ISI content
          const isiContent = document.querySelector(
            '.aaaem-isi-container__isi-content'
          ) as HTMLElement;
          if (isiContent) {
            const isiOffsetTop = isiContent?.offsetTop;
            utils.scrollToEl(isiOffsetTop).then(() => {
              scrollUtils.isiInViewCallback();
            });
          }

          // if a modal is open, close it
          if (document.body.classList.contains('js-modal-opened')) {
            const galleryModal = document.querySelector(
              '.modal-wrapper.is-open #gallery-pf'
            ) as HTMLElement;
            if (galleryModal) {
              CONSTANTS.galleryModalISIClicked = true;
            }

            const modalClose = document.querySelector(
              '.modal-wrapper.is-open .main-close'
            ) as HTMLElement;
            modalClose?.click?.();
          }
        }
      });

      // pauses scroll
      window.Bus.on('lo3:pauseScroll', () => {
        scrollUtils.pauseScroll();
      });

      // resumes scroll
      window.Bus.on('lo3:resumeScroll', () => {
        scrollUtils.resumeScroll();
      });

      // disables snap scroll temporarily
      window.Bus.on('lo3:disableSnapScroll', () => {
        scrollUtils.disableSnapScroll();
      });

      // enables snap scroll temporarily
      window.Bus.on('lo3:enableSnapScroll', () => {
        scrollUtils.enableSnapScroll();
      });

      // enables snap scroll forcefully
      window.Bus.on('lo3:forceEnableSnapScroll', () => {
        scrollUtils.forceEnableSnapScroll();
      });

      // disables snap scroll forcefully
      window.Bus.on('lo3:forceDisableSnapScroll', () => {
        scrollUtils.forceDisableSnapScroll();
      });

      // shows ISI on top of everything
      window.Bus.on('lo3:showISIOnTop', () => {
        document.body.classList.add('has-isi-on-top-layer');
      });

      // resets ISI to the regular layer
      window.Bus.on('lo3:resetISIOnTop', () => {
        document.body.classList.remove('has-isi-on-top-layer');
      });

      if (utils.isAuthorMode) {
        window.addEventListener('load', () => {
          window.Bus.emit('lo3:changeBgToLight');
        });
      }

      window.Bus.on('emu-video-embed:end', ({ id }) => {
        // closing modal of fist look screen, when the video inside it is completely played.
        if (id === 'first-look-story-embed') {
          const embed = document.querySelector('#first-look-story-embed');
          const modal = embed?.closest?.('.emu-modal');
          const close = modal?.querySelector('.close') as HTMLButtonElement;
          close?.click?.();
        }
      });
    }

    appendLoaderEvents();

    // for every section check and add testimonial link click functionality. If it is page fragments, wait for the page fragment to load and then add the callback
    addTestimonialsLinkClick();
    window.Bus?.on('emu-pf:loaded', ({ id }) => {
      if (id) {
        utils.waitForElement(`#${id}`).then(el => {
          if (el) {
            addTestimonialsLinkClick(el);
          }
        });
      }
    });
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
