(() => {
  // adds days to a given date
  const addDays = (date = new Date(), days = 1) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return {
      result,
      resultStr: result.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
    };
  };

  const initPlanner = () => {
    const CONSTANTS = {
      visit2Gap: 90,
      visit3Gap: 180,
    };

    const firstVisitInp = document.querySelector(
      '.start-journey__planner-visit-container--visit1 input'
    );
    const secondVisitInp = document.querySelector(
      '.start-journey__planner-visit-container--visit2 input'
    ) as HTMLInputElement;
    const thirdVisitInp = document.querySelector(
      '.start-journey__planner-visit-container--visit3 input'
    ) as HTMLInputElement;

    const secondATCButton = document.querySelector(
      '.emu-atc.start-journey__planner-atc-container--visit2'
    );
    const thirdATCButton = document.querySelector(
      '.emu-atc.start-journey__planner-atc-container--visit3'
    );

    // making the atc buttons disabled, until a value is selected for the start date
    secondATCButton?.classList.add('disabled');
    thirdATCButton?.classList.add('disabled');

    // when visit 1 date is selected, fill in the 2nd and the third visit dates automatically
    // part second visit by 90 days
    // part third visit by 90 days from second visit, or 180 days for first visit
    firstVisitInp?.addEventListener('changeDate', e => {
      //@ts-ignore
      const { detail } = e;
      if (detail.date) {
        const visit2Date = addDays(detail.date, CONSTANTS.visit2Gap);
        const visit3Date = addDays(detail.date, CONSTANTS.visit3Gap);
        secondVisitInp.value = visit2Date.resultStr;
        thirdVisitInp.value = visit3Date.resultStr;
        secondVisitInp.classList.add('has-value');
        thirdVisitInp.classList.add('has-value');

        secondATCButton?.setAttribute('data-start-date', secondVisitInp.value);
        secondATCButton?.setAttribute('data-end-date', secondVisitInp.value);
        //@ts-ignore
        secondATCButton?._update?.();
        secondATCButton?.classList.remove('disabled');

        thirdATCButton?.setAttribute('data-start-date', thirdVisitInp.value);
        thirdATCButton?.setAttribute('data-end-date', thirdVisitInp.value);
        //@ts-ignore
        thirdATCButton?._update?.();
        thirdATCButton?.classList.remove('disabled');
      }
    });
  };

  const init = () => {
    const planner = document.querySelector('.start-journey__planner');
    if (planner) {
      initPlanner();
    } else {
      window.Bus.on('lo3:pf-loaded', ({ id }) => {
        if (id === 'start-journey') {
          const planner = document.querySelector('.start-journey__planner');
          if (planner) {
            initPlanner();
          }
        }
      });
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
