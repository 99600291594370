/**
 * Adds fancy hover background based on the tab selection, for all the tab components
 * Adds dropdown related functionality for the tabs that follows this structure
 * Container - with class `custom-tabs`
 *    >(child) Container - with class `custom-tabs__select-wrapper`
 *         >(child) Form Options (V2)
 *    >(child) Tabs
 */

import { utils } from '../../utils';

// adds initial CSS variables to the tablist for the animation
// adds _updateStyles method to the tabs element to enable style updates to the tab pill background
const addTabsData = tabEl => {
  const tabTriggers = tabEl?.querySelectorAll('.aaaem-tabs__tab a');
  const curTabList = tabEl.querySelector('.aaaem-tabs__tablist');
  addTabbyListener();
  if (tabTriggers?.length) {
    // adding index for the tab triggers for using it to co-relate between select dropdown
    tabTriggers.forEach((trigger, index) => {
      trigger.setAttribute('data-index', '' + (index + 1));
      trigger.addEventListener('click', e => {
        e?.preventDefault?.();
      });
    });

    tabEl._updateStyles = () => {
      let curIndex = 0;
      tabTriggers.forEach((trigger, index) => {
        if (trigger.getAttribute('aria-selected') === 'true') {
          curIndex = index;
        }
      });

      curTabList?.style.setProperty(
        '--tab-width',
        '' + tabTriggers[0].clientWidth + 'px'
      );
      curTabList.style.setProperty('--active-tab-position', '' + curIndex);
    };

    // add class to tab element that has only one tab item. Based on that class tab pills and select dropdown will be hidden
    if (tabTriggers?.length === 1) {
      tabEl.classList.add('has-single-tab');
      tabEl.closest('.custom-tabs')?.classList?.add('has-single-tab');
    }

    tabEl._updateStyles();
  }
};

let tabbyAdded = false;
// adding a listener to the tabs
// - to update the position of the pseudo element(background) to the active trigger's place
// - to update the corresponding select dropdown if there is one
const addTabbyListener = () => {
  // makes sure that the tabby event is added only once, despite the no. of times the initializeCustomTabsFn is called
  if (tabbyAdded) return;
  tabbyAdded = true;

  document.addEventListener(
    'tabby',
    event => {
      const curTab = event.target as HTMLElement;
      const curIndex = curTab?.getAttribute('data-index');
      const tabsWrapper = curTab?.closest('.aaaem-tabs');

      // updating tab styles
      //@ts-ignore
      tabsWrapper?._updateStyles?.();

      // updating inner tab styles
      //@ts-ignore
      const content = event.detail?.content;
      if (content) {
        const nestedTabs = content.querySelectorAll('.aaaem-tabs');
        if (nestedTabs?.length) {
          nestedTabs.forEach(innerTab => {
            if (innerTab._updatedStyles) {
              innerTab._updatedStyles();
            } else {
              initializeCustomTabsFn(innerTab);
            }
          });
        }
      }

      // to pause any playing video when tab is switched
      if (tabsWrapper) {
        utils.pauseEmbedVideos({ section: tabsWrapper });
      }

      // updating select dropdown value based on active tab pills `data-index`
      const closestTabSelect = curTab.closest('.custom-tabs');
      if (closestTabSelect) {
        const select = closestTabSelect.querySelector(
          '.custom-tabs__select-wrapper select'
        ) as HTMLSelectElement;
        const selectId = select.closest('.emu-form-dropdown');

        if (select && selectId) {
          Array.from(select.options).forEach(option => {
            if (curIndex && option.value === curIndex) {
              option.selected = true;
            } else {
              option.selected = false;
            }
          });

          // updating form options v2 state
          // @ts-ignore
          select?._update?.();
        }
      }
    },
    false
  );
};

// custom tabs component will have a dropdown in mobile and tab pills in desktop for the tab selection
// this function keeps the tab pills in sync when the dropdown is changed
const syncTabSelectDropdown = tabEl => {
  const closestTabSelect = tabEl.closest('.custom-tabs');
  if (closestTabSelect) {
    const select = closestTabSelect.querySelector(
      '.custom-tabs__select-wrapper select'
    ) as HTMLSelectElement;
    const tabTriggers = closestTabSelect.querySelectorAll('.aaaem-tabs__tab a');

    select?.addEventListener('change', function () {
      const curValue = this.options[this.selectedIndex].text;
      tabTriggers.forEach(trigger => {
        const tabVal = trigger.getAttribute('data-emu-item-title');

        // guard to make sure that the event does not go into max call stack exceed error
        if (
          tabVal === curValue &&
          trigger.getAttribute('aria-selected') !== true
        ) {
          trigger?.click?.();
        }
      });
    });
  }
};

// when resized, updates the tab's active animation pseudo element's(background of the tab pill) width
const addResizeListener = tabEl => {
  let winWidth = window.innerWidth;
  window.addEventListener(
    'resize',
    utils.debounce(() => {
      const curWidth = window.innerWidth;
      if (curWidth !== winWidth) {
        winWidth = curWidth;

        const curActiveTabTrigger = tabEl?.querySelector(
          '.aaaem-tabs__tab a[aria-selected=true]'
        );
        const curTabList = tabEl?.querySelector('.aaaem-tabs__tablist');
        if (curActiveTabTrigger && curTabList) {
          curTabList.style.setProperty(
            '--tab-width',
            '' + curActiveTabTrigger.clientWidth + 'px'
          );
        }
      }
    }, 600)
  );
};

const initializeCustomTabsFn = tabEl => {
  addTabsData(tabEl);
  addResizeListener(tabEl);
  syncTabSelectDropdown(tabEl);
};

export default initializeCustomTabsFn;
