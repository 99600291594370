const _temp0 = require("./components/add-to-calendar/_add-to-calendar.scss");

const _temp1 = require("./components/alle-banner/_alle-banner.scss");

const _temp2 = require("./components/button/_button.scss");

const _temp3 = require("./components/container-isi/_container-isi.scss");

const _temp4 = require("./components/datepicker/_datepicker.scss");

const _temp5 = require("./components/embed/_embed.scss");

const _temp6 = require("./components/floating-cta/_floating-cta.scss");

const _temp7 = require("./components/footer/_footer.scss");

const _temp8 = require("./components/form-dropdown/_form-dropdown.scss");

const _temp9 = require("./components/header/_header.scss");
const _temp10 = require("./components/header/_nav.scss");

const _temp11 = require("./components/modal/_modal.scss");

const _temp12 = require("./components/tabs/tabs.scss");

const _temp13 = require("./components/teaser/_teaser.scss");

const _temp14 = require("./components/text/_text.scss");

module.exports = {
  "add-to-calendar":   {
    "_add-to-calendar": _temp0
  },
  "alle-banner":   {
    "_alle-banner": _temp1
  },
  "button":   {
    "_button": _temp2
  },
  "container-isi":   {
    "_container-isi": _temp3
  },
  "datepicker":   {
    "_datepicker": _temp4
  },
  "embed":   {
    "_embed": _temp5
  },
  "floating-cta":   {
    "_floating-cta": _temp6
  },
  "footer":   {
    "_footer": _temp7
  },
  "form-dropdown":   {
    "_form-dropdown": _temp8
  },
  "header":   {
    "_header": _temp9,
    "_nav": _temp10
  },
  "modal":   {
    "_modal": _temp11
  },
  "tabs":   {
    "tabs": _temp12
  },
  "teaser":   {
    "_teaser": _temp13
  },
  "text":   {
    "_text": _temp14
  }
}