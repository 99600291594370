import { scrollUtils } from './scroll-utils';
import { utils } from './utils';

(() => {
  // dispatches a proxy scroll event
  let dispatchTimer;
  const dispatchProxyScrollEvent = dir => {
    if (dispatchTimer) {
      return;
    }

    scrollUtils.scrollToNextSection(dir);

    dispatchTimer = setTimeout(() => {
      dispatchTimer = null;
    }, 1000);
  };

  // when mouse wheel is scrolled. Accounts for laptop's touch pads too
  const wheelScrollEvent = e => {
    if (scrollUtils.snapScrollOn || scrollUtils.scrollPaused) {
      e.preventDefault();
      let scrollDir = 'down';

      if (e.deltaY < 0) {
        scrollDir = 'up';
      }

      // scroll with sensitive touch pads gets triggered frequently. Adding a delta variable to make sure that the triggers are restricted to bigger values
      if (e.deltaY * Math.sign(e.deltaY) > 20) {
        dispatchProxyScrollEvent(scrollDir);
      }
      return;
    }

    // scroll with sensitive touch pads gets triggered frequently. Adding a delta variable to make sure that the triggers are restricted to bigger values
    if (e.deltaY * Math.sign(e.deltaY) < 10) {
      e.preventDefault();
      return;
    }
  };

  // when keys are pressed to scroll the page
  // left: 37, up: 38, right: 39, down: 40,
  // space-bar: 32, page-up: 33, page-down: 34, end: 35, home: 36
  const keyCodes = [37, 38, 39, 40, 33, 34, 35, 36];
  const upKeyCodes = [38, 33, 36, 37];
  const scrollPageWithKeys = e => {
    if (scrollUtils.snapScrollOn || scrollUtils.scrollPaused) {
      const curKeyCode = e.keyCode;
      if (keyCodes.indexOf(curKeyCode) >= 0) {
        e.preventDefault();

        let scrollDir = 'down';
        if (upKeyCodes.indexOf(curKeyCode) >= 0) {
          scrollDir = 'up';
        }

        // for home and end keys, scroll to the top or end of the page
        if (e.keyCode === 36) {
          window.scrollTo({
            top: 0,
            behavior: 'instant',
          });
          return;
        } else if (e.keyCode === 35) {
          window.scrollTo({
            top: document.body.offsetHeight,
            behavior: 'instant',
          });
          return;
        }
        dispatchProxyScrollEvent(scrollDir);
        return;
      }
    }
  };

  // touch events when the page is scrolled
  let timer;
  let touchStartPosY;
  let touchStartPosX;

  const handleTouchStart = evt => {
    touchStartPosX = evt.touches[0].clientX;
    touchStartPosY = evt.touches[0].clientY;
  };

  const scrollPageWithTouch = evt => {
    if (scrollUtils.snapScrollOn || scrollUtils.scrollPaused) {
      evt.preventDefault();
      evt.stopPropagation();

      if (!touchStartPosY || !touchStartPosX) {
        return;
      }

      let xUp = evt.touches[0].clientX;
      let yUp = evt.touches[0].clientY;

      let xDiff = touchStartPosX - xUp;
      let yDiff = touchStartPosY - yUp;
      let scrollDir = 'down';

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        if (xDiff > 0) {
          return;
        } else {
          return;
        }
      } else {
        if (yDiff > 0) {
          scrollDir = 'down';
        } else {
          scrollDir = 'up';
        }
      }
      /* reset values */
      touchStartPosY = null;
      touchStartPosX = null;

      // debouncing to reduce frequent triggers
      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(() => {
        dispatchProxyScrollEvent(scrollDir);
      }, 50);

      return;
    }
  };

  // appends events to handle the scroll of the page
  const appendScrollEvents = () => {
    // mouse wheel event
    document.addEventListener('wheel', wheelScrollEvent, {
      passive: false,
    });

    const isTouchDevice =
      'ontouchstart' in window || navigator.maxTouchPoints > 0;
    if (isTouchDevice) {
      // touch move event
      window.addEventListener('touchstart', handleTouchStart, {
        passive: false,
      });

      // touch move event
      window.addEventListener('touchmove', scrollPageWithTouch, {
        passive: false,
      });
    }

    // keyboard event
    document.addEventListener('keydown', scrollPageWithKeys, false);
  };

  // appends events to handle resize events
  const appendResizeEvents = () => {
    let winWidth = window.innerWidth;
    window.addEventListener('resize', () => {
      const curWinWidth = window.innerWidth;
      if (curWinWidth !== winWidth) {
        scrollUtils.handleSectionBusEvents();
        winWidth = curWinWidth;
      }
    });
  };

  // executed once the page is loaded
  const pageLoadedCallback = () => {
    window.Bus.emit('lo3:hideLoader');
    window.Bus.emit('lo3:pageCompletelyLoaded');
  };

  // loads content of the page fragments, and appends functionality
  const handlePageFragments = () => {
    const pageFragments = document.querySelectorAll('.page-fragment');

    // when the page has to load with page fragments, make the page fragments load and then trigger pageLoadedCallback
    // if not page fragments are found, call pageLoadedCallback immediately after the page is ready
    if (pageFragments?.length) {
      const totalFragmentCount = pageFragments.length;
      let loadedFragmentCount = 0;
      pageFragments.forEach(el => {
        const pageId = el.id;
        if (document.body.classList.contains('show-pf-staggered')) {
          // loading page fragments slowly when user scrolls to the section
          const options = {
            threshold: 1,
          };

          const observer = new IntersectionObserver(function (entries) {
            entries.forEach(entry => {
              if (entry.isIntersecting) {
                window.Bus.emit('lo3:showLoader');
                window.Bus.emit(`emu-pf-${pageId}-load`);
                observer.disconnect();
              }
            });
          }, options);
          observer.observe(el);
        } else {
          // loading the page fragments, as soon as the page is loaded
          window.Bus.emit(`emu-pf-${pageId}-load`);
        }

        window.Bus?.on?.(`emu-pf:loaded`, ({ id }) => {
          if (id === pageId) {
            utils
              .waitForElement(`.${scrollUtils.sectionClass}`, el)
              .then(() => {
                const sectionWrapper = el.querySelector(
                  `.${scrollUtils.sectionWrapperClass}`
                );

                if (document.body.classList.contains('show-pf-staggered')) {
                  pageLoadedCallback();
                }

                sectionWrapper?.classList.remove(
                  scrollUtils.sectionWrapperClass
                );

                const allSectionWrappers = document.querySelectorAll(
                  `.${scrollUtils.sectionWrapperClass}`
                );
                if (allSectionWrappers?.length) {
                  //@ts-ignore
                  scrollUtils.allSectionWrappers =
                    Array.from(allSectionWrappers);
                }

                addObserversToContainers(sectionWrapper);
                utils.initTooltips(sectionWrapper);
                // utils.initATC(sectionWrapper);
                utils.initDatepickers(sectionWrapper);

                // if the scroll direction is up, changing the scroll position of the window to show the last section in view
                if (document.body.classList.contains('show-pf-staggered')) {
                  if (scrollUtils.scrollDir === 'up') {
                    const sectionContainers = sectionWrapper?.querySelectorAll(
                      `.${scrollUtils.sectionContainerClass}`
                    );
                    if (sectionContainers?.length) {
                      const lastSectionContainer =
                        sectionContainers[sectionContainers?.length - 1];
                      if (lastSectionContainer) {
                        window.scrollTo({
                          //@ts-ignore
                          top: lastSectionContainer.offsetTop,
                          behavior: 'instant',
                        });
                      }
                    }
                  }
                }

                window.Bus.emit('lo3:pf-loaded', {
                  id: pageId,
                });
              });

            loadedFragmentCount += 1;
            if (loadedFragmentCount === totalFragmentCount) {
              pageLoadedCallback();
            }
          }
        });
      });
    } else {
      if (document.readyState === 'complete') {
        pageLoadedCallback();
      } else {
        window.addEventListener('load', pageLoadedCallback);
      }
    }
  };

  // adds observers to the containers with section class names.
  // observes and makes the sections active/inactive
  const addObserversToContainers = (parent?) => {
    const options = {
      threshold: scrollUtils.snapScrollOn ? 0.8 : [0.1, 0.4, 0.6, 0.9],
    };
    const ratioLimit = scrollUtils.snapScrollOn ? 0.8 : 0.6;

    const observer = new IntersectionObserver(function (entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting && entry.intersectionRatio >= ratioLimit) {
          scrollUtils.makeSectionContainerActive(entry.target);
        }
      });
    }, options);

    let rootElement =
      parent || document.querySelector(`.${scrollUtils.sectionWrapperClass}`);

    const sectionContainers = rootElement?.children
      ? [...rootElement?.children]
      : []; // .container class elements

    if (sectionContainers?.length) {
      sectionContainers.forEach(el => {
        if (el.classList.contains(scrollUtils.sectionContainerAEMClass)) {
          el.classList.add(scrollUtils.sectionContainerClass);
          if (el.querySelector(`.${scrollUtils.animatedSubsectionClass}`)) {
            el.querySelector(`.${scrollUtils.sectionClass}`)?.classList.add(
              scrollUtils.hasAnimatedSubsectionClass
            );
          }

          observer.observe(el);
        }
      });
    }
  };

  // updates a variable in the scrollUtils to save the direction the user is scrolling
  const addDirectionTracker = () => {
    let curScrollPos = window.scrollY;
    window.addEventListener('scroll', () => {
      const scrollPos = window.scrollY;
      scrollUtils.scrollDir = scrollPos > curScrollPos ? 'down' : 'up';
      curScrollPos = scrollPos;
    });
  };

  // initialization
  const initScrollManager = () => {
    // no need to manage scroll for other pages except home page
    if (!document.body.classList.contains('home') || utils?.isAuthorMode) {
      window.Bus.emit('lo3:changeBgToLight');
      return;
    }

    // deciding whether the snap scroll should be on or off
    scrollUtils.enableSnapScroll();

    // adding scroll events
    appendScrollEvents();

    // adding resize events
    appendResizeEvents();

    window.Bus.emit('lo3:showLoader');
    // handle page fragments
    if (document.readyState === 'complete') {
      handlePageFragments();
    } else {
      window.addEventListener('load', handlePageFragments);
    }

    // looping through available section wrappers and adding listeners to the immediate .container elements available in them
    const allSectionWrappers = document.querySelectorAll(
      `.${scrollUtils.sectionWrapperClass}`
    );
    if (allSectionWrappers?.length) {
      //@ts-ignore
      scrollUtils.allSectionWrappers = Array.from(allSectionWrappers);

      allSectionWrappers.forEach(el => {
        addObserversToContainers(el);
      });
    } else {
      addObserversToContainers();
    }

    // adds scroll direction to a variable
    addDirectionTracker();

    // initializing tooltips and select boxes
    utils.initTooltips();
    // utils.initATC();
    utils.initDatepickers();
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', initScrollManager);
  } else {
    initScrollManager();
  }
})();
