import { scrollUtils } from './scroll-utils';
import { utils } from './utils';

(() => {
  const CONSTANTS = {
    mobileAnchors: null,
    deskAnchors: null,
    curId: '',
    header: {
      closeIconId: 'tl3-desk-dropdown-close',
      menuTriggerId: 'tl3-menu-trigger',
    },
    mobileTrigger: document.querySelector(
      '.header__mobile-trigger'
    ) as HTMLElement,
    sectionIds: [],
  };

  const callScroll = (el?) => {
    if (el) {
      utils.scrollToEl(el);
    }
    window.Bus.emit('lo3:hideLoader');
  };

  const scrollToSection = (href, e?) => {
    const sectionOnPage = document.querySelector(href) as HTMLElement;
    if (sectionOnPage) {
      e?.preventDefault?.();
      if (sectionOnPage.classList.contains(`${scrollUtils.sectionClass}`)) {
        const closestContainer = sectionOnPage.closest(
          `.${scrollUtils.sectionContainerAEMClass}`
        ) as HTMLElement;

        callScroll(closestContainer);
      } else {
        callScroll(sectionOnPage);
      }
      return;
    }

    CONSTANTS.sectionIds = CONSTANTS.sectionIds || [];
    if (CONSTANTS.sectionIds.length === 0) {
      const allSections = document.querySelectorAll(
        `.${scrollUtils.sectionWrapperClass}`
      );
      if (allSections?.length) {
        allSections.forEach(section => {
          // @ts-ignore
          CONSTANTS.sectionIds.push(section.id);
        });
      }
    }

    const hrefWithoutHash = href.replace('#', '');
    let isExactSection = false;
    let section;
    let subsectionId = '';
    let sectionId;

    CONSTANTS.sectionIds.forEach(id => {
      if (id === hrefWithoutHash || hrefWithoutHash.includes(`${id}-`)) {
        isExactSection = id === hrefWithoutHash;
        section = document.querySelector(`#${id}`)!;
        if (isExactSection !== true) {
          subsectionId = hrefWithoutHash.replace(`${id}-`, '');
          sectionId = id;
        }
      }
    });

    if (section instanceof HTMLElement) {
      scrollUtils.hideAllAnimatedSubsections();
      if (sectionId && subsectionId && section) {
        e?.preventDefault?.();
        window.Bus.on('lo3:pf-loaded', ({ id }) => {
          if (id === sectionId) {
            const closestContainer = document
              .querySelector(href)
              .closest(`.${scrollUtils.sectionContainerAEMClass}`);
            callScroll(closestContainer);
          }
        });

        window.Bus.emit(`emu-pf-${sectionId}-load`);
      } else if (section) {
        e?.preventDefault?.();
        callScroll(section);
      }
    } else {
      callScroll();
    }
  };

  // for all the anchors on the page that has a hash, and if the hash is an actual id of an element in the page,
  // scroll the page to the section.
  // if the mobile menu is open during this, close the menu
  const appendEventsToAnchors = () => {
    const anchors = document.querySelectorAll(
      'a[href^="#"]:not([href^="#tabs-"]:not([href="#"])'
    ); // excluding tabs coz tabs have hash and this should not work for tab triggers

    if (anchors?.length) {
      anchors.forEach(el => {
        el.addEventListener('click', e => {
          const href = el.getAttribute('href')!;
          if (href !== '#') {
            scrollToSection(href, e);
            const scrollSection = document.querySelector(href) as HTMLElement;

            if (scrollSection) {
              e.preventDefault();

              updateAnchors(CONSTANTS.deskAnchors, href, true);
              updateAnchors(CONSTANTS.mobileAnchors, href, true);
              if (CONSTANTS.mobileTrigger?.classList.contains('js-toggle-on')) {
                CONSTANTS.mobileTrigger.click();
              }
            }
          }
        });
      });
    }
  };

  // updates anchors in the menu with active and prev-active classes for styles
  const updateAnchors = (anchors, id, fromClick?) => {
    let activeIndex;
    const idWithoutHash = id?.replace('#', '');
    anchors.forEach((el, i) => {
      const href = el.getAttribute('data-href')?.replace('#', '');

      if (href === idWithoutHash) {
        activeIndex = i;
        CONSTANTS.curId = id;
      }

      if (fromClick) {
        el.classList.add('no-transition');
      } else {
        el.classList.remove('no-transition');
      }
    });

    anchors.forEach((el, i) => {
      if (activeIndex || activeIndex === 0) {
        if (i < activeIndex) {
          el.classList.add('prev-active', 'active');
        } else if (i === activeIndex) {
          el.classList.add('active');
          el.classList.remove('prev-active');
        } else {
          el.classList.remove('prev-active', 'active');
        }
      }
    });
  };

  // This function will help to close the menu when close icon is clicked
  const menuCloseHandler = (closeIconId: string, menuTriggerId: string) => {
    const menuTrigger = document.querySelector(
      '#' + menuTriggerId
    )! as HTMLElement;
    const closeIconBtn = document.querySelector(
      '#' + closeIconId
    )! as HTMLElement;
    closeIconBtn?.addEventListener('click', e => {
      e.preventDefault();
      menuTrigger?.click();
    });
  };

  const init = () => {
    CONSTANTS.mobileTrigger = document.querySelector(
      '.header__mobile-trigger'
    ) as HTMLElement;

    const deskAnchors = document.querySelectorAll(
      '.header__sidenav-container li'
    );
    const mobileAnchors = document.querySelectorAll('.header__nav-primary li');

    deskAnchors.forEach(el => {
      const href = el.querySelector('a')?.getAttribute('href')!;
      el.setAttribute('data-href', href);
    });

    mobileAnchors.forEach(el => {
      const href = el.querySelector('a')?.getAttribute('href')!;
      el.setAttribute('data-href', href);
    });

    //@ts-ignore
    CONSTANTS.mobileAnchors = mobileAnchors;
    //@ts-ignore
    CONSTANTS.deskAnchors = deskAnchors;

    window.Bus?.on('lo3:updateMenuState', () => {
      const activeSectionContainer = scrollUtils.getCurActiveSectionContainer();
      if (activeSectionContainer) {
        const parentId = activeSectionContainer
          .closest(`.${scrollUtils.sectionWrapperClass}`)
          ?.getAttribute?.('id');

        if (parentId && parentId !== CONSTANTS.curId) {
          updateAnchors(
            deskAnchors,
            parentId,
            scrollUtils.skipAnimationLastWaiting
          );
          updateAnchors(
            mobileAnchors,
            parentId,
            scrollUtils.skipAnimationLastWaiting
          );
        }
      }
    });

    // when menu trigger button is toggled, toggle the snap scroll
    window.Bus.on('emu-button:click', ({ toggleOn, id }) => {
      if (id === 'mobile-menu-trigger') {
        if (toggleOn) {
          document.body.classList.add('has-mobile-menu-open');
          scrollUtils.disableSnapScroll();
        } else {
          document.body.classList.remove('has-mobile-menu-open');
          scrollUtils.enableSnapScroll();
        }
      }
    });

    // making sure that menu gets closed when window is resized
    window.addEventListener('resize', () => {
      if (
        window.innerWidth >= 1024 &&
        document.body.classList.contains('has-mobile-menu-open')
      ) {
        scrollUtils.enableSnapScroll();
      }
    });

    // appending events to all anchors with hrefs that start with #
    appendEventsToAnchors();

    // handle menu close
    menuCloseHandler(
      CONSTANTS.header.closeIconId,
      CONSTANTS.header.menuTriggerId
    );

    if (window.location.hash && window.location.hash !== '#testimonials') {
      window.Bus.on('lo3:pageCompletelyLoaded', () => {
        window.Bus.emit('lo3:showLoader');
        scrollToSection(window.location.hash);
      });
    }

    window.addEventListener('hashchange', () => {
      scrollToSection(window.location.hash);
    });
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
