import initializeCustomTabsFn from './components/tabs/tabs';
import initDatepickers from './components/datepicker/datepicker';
import Player from '@vimeo/player';

export const utils = {
  tooltipContainerClass: 'tooltip__container',
  sectionWrapperIds: [],

  isAuthorMode:
    (window?.parent || window)?.location.href.indexOf('/editor.html/') >= 0,

  scrollToEl(entity: HTMLElement | String | Number, isSmooth?: Boolean) {
    return new Promise(res => {
      if (entity instanceof HTMLElement) {
        const scrollBounds = entity.offsetTop;
        this.scrollTo(scrollBounds, isSmooth).then(() => {
          res('');
        });
      } else if (entity === 'down' || entity === 'up') {
        const scrollFactor = entity === 'down' ? 1 : -1;

        let scrollToPos = window.scrollY + scrollFactor * window.innerHeight;
        if (
          scrollFactor === -1 &&
          window.scrollY + window.innerHeight === document.body.scrollHeight &&
          document.body.scrollHeight % window.innerHeight > 0
        ) {
          scrollToPos =
            window.scrollY +
            scrollFactor * (document.body.scrollHeight % window.innerHeight);
        }

        this.scrollTo(scrollToPos, isSmooth).then(() => {
          res('');
        });
      } else if (typeof entity === 'number') {
        this.scrollTo(entity, isSmooth).then(() => {
          res('');
        });
      }
    });
  },

  scrollTo(offset, isSmooth?) {
    return new Promise(res => {
      const fixedOffset = offset.toFixed();
      let timer;
      timer = setTimeout(() => {
        timer = null;
        window.scrollTo({
          top: offset,
          behavior: isSmooth ? 'smooth' : 'instant',
        });
        window.removeEventListener('scroll', onScroll);
        res(true);
      }, 600);

      const onScroll = function () {
        if (window.scrollY.toFixed() === fixedOffset) {
          window.removeEventListener('scroll', onScroll);
          if (timer) {
            clearTimeout(timer);
          }

          res(true);
        }
      };

      window.addEventListener('scroll', onScroll);
      onScroll();

      const offsetDiff = offset - window.scrollY;
      window.scrollBy({
        top: offsetDiff,
        behavior: isSmooth ? 'smooth' : 'instant',
      });
    });
  },

  // event to listen to transitionend event, but with a max timer of 1000ms
  // sometimes transition could even end before the event gets appended. Making sure that there is a limit to the waiting period in such cases
  transitionend(el, callback, maxTime = 1000) {
    const callbackFn = () => {
      callback();
      if (timer) {
        clearTimeout(timer);
      }
      el.removeEventListener('transitionend', callbackFn);
    };

    let timer;
    if (maxTime) {
      timer = setTimeout(callbackFn, maxTime);
    }

    el.addEventListener('transitionend', callbackFn, {
      once: true,
    });
  },

  // plays a plyr video for a given instance ID
  playVideo(embed, mute?) {
    const instanceId = embed?.id;
    const videoInst = window._plyrInstances?.[instanceId];
    if (videoInst && videoInst?.playing !== true) {
      if (mute) {
        videoInst.mute = true;
        videoInst?.setVolume?.(0);
      }
      videoInst?.play?.();
    } else if (!videoInst && Player) {
      const iframe = embed?.querySelector?.('iframe');
      if (iframe) {
        const vimeoPlayer = new Player(iframe);
        if (vimeoPlayer) {
          if (mute) {
            vimeoPlayer.setVolume?.(0);
          }
          vimeoPlayer.play?.();
        }
      }
    }
  },

  // pauses a video if it is playing, for a given instance ID
  pauseVideo(instanceId) {
    const videoInst = window._plyrInstances?.[instanceId];
    if (videoInst?.playing) {
      videoInst?.pause?.();
    }
  },

  // stops a video if it is playing, for a given instance ID
  stopVideo(instanceId) {
    const videoInst = window._plyrInstances?.[instanceId];
    if (videoInst?.playing) {
      videoInst?.stop?.();
    }
  },

  // pauses videos inside an embed
  pauseEmbedVideos(e, isStop?) {
    const { section, id } = e;
    // home page banner can play as is, as it is a background video
    if (id && (id === 'home-banner' || id === 'home-banner-inner')) {
      return;
    }

    const embeds = section.querySelectorAll('.emu-video-plyr');
    if (embeds?.length) {
      embeds.forEach(el => {
        const embedId = el.getAttribute('id');
        if (embedId) {
          if (isStop) {
            this.stopVideo(embedId);
          } else {
            this.pauseVideo(embedId);
          }
        }
      });
    }
  },

  // appends events for the tooltip buttons
  initTooltips(parent?) {
    const wrapper = parent || document;
    const tooltipButtons = wrapper.querySelectorAll(
      `.${this.tooltipContainerClass} .aaaem-button`
    );

    tooltipButtons.forEach(tooltipButton => {
      tooltipButton.addEventListener('click', e => {
        e.stopPropagation();
        const tooltipWrapper = tooltipButton.closest(
          `.${this.tooltipContainerClass}`
        );

        if (tooltipWrapper) {
          tooltipWrapper.classList.add(
            `${this.tooltipContainerClass}--visible`
          );

          window.addEventListener(
            'click',
            () => {
              tooltipWrapper.classList.remove(
                `${this.tooltipContainerClass}--visible`
              );
            },
            { once: true }
          );
        }
      });
    });
  },

  // hides tooltips of a specific section
  hideTooltips(section) {
    const activePopup = section?.querySelector(
      `.${this.tooltipContainerClass}--visible`
    );
    if (activePopup) {
      activePopup.classList.remove(`${this.tooltipContainerClass}--visible`);
    }
  },

  // initializes add to calendar button
  // initATC(parent?) {
  //   const wrapper = parent || document;
  //   initATC(wrapper);
  // },

  // initializes add to calendar button
  initDatepickers(parent?) {
    const wrapper = parent || document;
    initDatepickers(wrapper);
  },

  // waits for element to populate and resolves with the found element
  waitForElement(selector, parent?) {
    const root = parent || document.body;
    return new Promise(resolve => {
      if (root.querySelector(selector)) {
        return resolve(root.querySelector(selector));
      }

      const observer = new MutationObserver(() => {
        if (root.querySelector(selector)) {
          resolve(root.querySelector(selector));
          observer.disconnect();
        }
      });

      observer.observe(root, {
        childList: true,
        subtree: true,
      });
    });
  },

  // returns a random number
  getRandomId() {
    return Math.round(Math.random() * 999999999);
  },

  // plays lottie animation with a given instance id
  playLottieAnimation(id) {
    if (id) {
      const lottieInstance = window._lottieInstances?.[id];
      lottieInstance?.seek?.(0);
      lottieInstance?.play?.();
    }
  },

  initTabsFn(tabEl) {
    initializeCustomTabsFn(tabEl);
  },

  debounce(func, delay) {
    let debounceTimer;
    return function () {
      //@ts-ignore
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  },

  getActiveTabs(modalEl) {
    let mainTabVal = 1,
      subTabVal = 1;

    const mainTab = modalEl?.querySelector(
      '.video-testimonials__main-tabs-wrapper .custom-tabs__select-wrapper .options li[aria-selected="true"]'
    );
    mainTabVal = mainTab?.getAttribute('data-value');

    const subTabPanel = modalEl?.querySelector(
      '.video-testimonials__main-tabs > .aaaem-tabs__tabpanels > div.aaaem-tabs__tabpanel:not([hidden])'
    );
    const subTab = subTabPanel?.querySelector(
      '.custom-tabs__select-wrapper .options li[aria-selected="true"]'
    );
    subTabVal = subTab?.getAttribute('data-value');

    return [mainTabVal, subTabVal];
  },

  // hides open datepicker with a given instance id
  hideDatepicker(id) {
    if (id) {
      const datepickerInstance = window._datepickerInstance?.[id];
      if (datepickerInstance?.active) {
        datepickerInstance?.hide?.();
      }
    }
  },
};
