import { scrollUtils } from './scroll-utils';
import { utils } from './utils';

(() => {
  const handleProxyScroll = section => {
    if (section) {
      // turning off browser scroll
      scrollUtils.pauseScroll();

      // turning off any previous events
      window.Bus.off('lo3:proxyScroll');

      // constants for the element
      const proxyContainers = section.querySelectorAll(
        `.${scrollUtils.animatedSubsectionClass}`
      );
      const trackerArray: any = [];
      let activeIndex = -1; // making sure that the first active item is always 0

      // activates an animated section
      const activateNext = nextActiveParent => {
        if (nextActiveParent) {
          if (
            nextActiveParent.classList.contains(
              scrollUtils.animatedSubsectionClass
            )
          ) {
            nextActiveParent.style.height =
              nextActiveParent.scrollHeight + 'px';
          }

          nextActiveParent.classList.add(
            scrollUtils.animatedSubsectionActiveClass
          );
          nextActiveParent.classList.remove(
            scrollUtils.animatedSubsectionPrevActiveClass
          );

          window.Bus.emit('lo3:proxyScrollSectionActivated', {
            el: nextActiveParent,
            trackerArray,
            activeIndex,
          });
        }
      };

      // creating a simple array with the elements that must change state on proxy scroll in order
      proxyContainers.forEach((el, index) => {
        const parentId = utils.getRandomId();

        if (el.classList.contains(scrollUtils.animatedSubsectionActiveClass)) {
          activeIndex = index;
        }

        trackerArray.push({
          el,
          id: parentId,
          type: 'parent',
        });
      });

      if (proxyContainers?.length) {
        // adding listener for proxy scroll event
        window.Bus.on('lo3:proxyScroll', ({ dir, index }) => {
          if (dir === 'down') {
            activeIndex += 1;
          } else {
            activeIndex -= 1;
          }

          if (index || index === 0) {
            activeIndex = index;
          }

          const curEl = trackerArray[activeIndex];
          let nextActiveParent;
          let curActiveParent;

          if (curEl || (activeIndex >= -1 && dir === 'up')) {
            trackerArray.forEach(item => {
              if (item.id === curEl?.id || item.id === curEl?.parentId) {
                nextActiveParent = item.el;
              } else if (
                item.el.classList.contains(
                  scrollUtils.animatedSubsectionActiveClass
                )
              ) {
                curActiveParent = item.el;
              }
            });

            if (curActiveParent) {
              // when there is an show-immediate class and when user is scrolling up, resume the scroll and show previous section
              if (
                dir === 'up' &&
                activeIndex === -1 &&
                curActiveParent.classList.contains(
                  scrollUtils.animatedSubsectionImmediateShowClass
                )
              ) {
                scrollUtils.resumeScroll();
                scrollUtils.scrollToNextSection(dir);
                return;
              }

              curActiveParent.addEventListener(
                'transitionend',
                () => {
                  if (
                    curActiveParent.classList.contains(
                      scrollUtils.animatedSubsectionClass
                    )
                  ) {
                    curActiveParent.style.height = '0';
                  }
                  activateNext(nextActiveParent);
                },
                {
                  once: true,
                }
              );

              window.Bus.emit('lo3:proxyScrollSectionDeactivated', {
                el: curActiveParent,
                trackerArray,
                activeIndex,
              });

              curActiveParent.classList.add(
                scrollUtils.animatedSubsectionPrevActiveClass
              );
              curActiveParent.classList.remove(
                scrollUtils.animatedSubsectionActiveClass
              );
            } else {
              activateNext(nextActiveParent);
            }
          } else {
            // when user needs to switch out of proxy scroll, scroll to the next section
            if (activeIndex < -1 || activeIndex >= proxyContainers.length) {
              scrollUtils.resumeScroll();
              scrollUtils.scrollToNextSection(dir);
            }
          }

          // hide any open tooltips when user scrolls
          utils.hideTooltips(curEl?.el);
        });

        // when there is an show-immediate class and when user is scrolling down, immediately show the first section
        if (
          scrollUtils.scrollDir === 'down' &&
          trackerArray[0]?.el.classList.contains(
            scrollUtils.animatedSubsectionImmediateShowClass
          )
        ) {
          window.Bus.emit('lo3:proxyScroll', { dir: 'down', index: 0 });
        } else if (scrollUtils.scrollDir === 'down' && activeIndex === -1) {
          activeIndex = 0;
          window.Bus.emit('lo3:proxyScroll', { dir: 'up' });
        }

        if (scrollUtils.scrollDir === 'up' && activeIndex === -1) {
          activeIndex = trackerArray.length;
          window.Bus.emit('lo3:proxyScroll', { dir: 'up' });
        }
      }
    }
  };

  const init = () => {
    if (window.Bus) {
      window.Bus.on('lo3:sectionActivated', ({ section }) => {
        if (
          section?.classList.contains(scrollUtils.hasAnimatedSubsectionClass)
        ) {
          handleProxyScroll(section);
        }
      });

      window.addEventListener('resize', () => {
        const animatedSubsection = document.querySelectorAll(
          `.${scrollUtils.animatedSubsectionClass}.${scrollUtils.animatedSubsectionActiveClass}`
        )! as NodeListOf<HTMLElement>;
        if (animatedSubsection?.length) {
          animatedSubsection.forEach(el => {
            el.style.height = el.scrollHeight + 'px';
          });
        }
      });
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
