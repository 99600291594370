// the testimonial carousel will be loaded via Page Fragments and the carousel config will not be automatically set.
// So adding it here to make sure that the carousel config is applied appropriately

import { utils } from './utils';

(() => {
  const handleCarouselGradient = e => {
    if (e.id === 'testimonial') {
      let carouselInfo = window._tnsInstances?.['testimonial']?.getInfo?.();

      if (carouselInfo) {
        const tnsOuter = carouselInfo.container?.closest?.(
          '.tns-outer'
        ) as HTMLElement;
        if (tnsOuter) {
          // end of carousel
          if (carouselInfo.index === 0) {
            tnsOuter.classList.remove('prevActive');
            tnsOuter.classList.add('nextActive');
          } else if (
            carouselInfo.index >=
            carouselInfo.slideCount - carouselInfo.slideBy
          ) {
            tnsOuter.classList.add('prevActive');
            tnsOuter.classList.remove('nextActive');
          } else {
            tnsOuter.classList.add('nextActive');
            tnsOuter.classList.add('prevActive');
          }
        }
      }
    }
  };

  const showVideoTestimonial = (slide, showFirstSubTab = false) => {
    const id = slide.id;
    const mainTabVal = id?.split('-')[2];
    const subTabVal = id?.split('-')[3];

    window.Bus.emit('lo3:showVideoTestimonials', {
      mainTabVal,
      subTabVal: showFirstSubTab ? 1 : subTabVal,
    });
  };

  // adding click events to the elements inside the carousel
  const addCarouselSlideClickEvents = () => {
    const carouselSlides = document.querySelectorAll('.testimonial-slide');
    if (carouselSlides?.length) {
      carouselSlides.forEach(slide => {
        slide.addEventListener('click', () => {
          showVideoTestimonial(slide);
        });
      });

      // code to randomly show video testimonial
      window.Bus.on('lo3:showRandomVideoTestimonials', slide => {
        showVideoTestimonial(slide || carouselSlides[0], true);
      });
    }
  };

  const showRandomTestimonial = () => {
    const carouselSlides = document.querySelectorAll('.testimonial-slide');
    const randomSlideNum = Math.ceil(Math.random() * carouselSlides.length);
    const randomSlide = carouselSlides[randomSlideNum] || carouselSlides[0];
    window.Bus.emit('lo3:showRandomVideoTestimonials', randomSlide);
  };

  const initRealResults = () => {
    if (window._tnsInstances?.['testimonial']) {
      // show gradient on left and right side in testimonial carousel
      window.Bus.on('emu-carousel:nextClick', handleCarouselGradient);
      window.Bus.on('emu-carousel:prevClick', handleCarouselGradient);
    }
    addCarouselSlideClickEvents();

    if (window.location.hash === '#testimonials') {
      showRandomTestimonial();
    }

    window.addEventListener('hashchange', () => {
      if (window.location.hash === '#testimonials') {
        showRandomTestimonial();
      }
    });
  };

  const init = () => {
    const realResults = document.querySelector('.real-results--screen-3');
    if (realResults) {
      initRealResults();
    } else {
      window.Bus.on('lo3:pf-loaded', ({ id }) => {
        if (id === 'real-results') {
          utils
            .waitForElement(`.real-results--screen-3 .tns-inner`)
            .then(el => {
              if (el) {
                initRealResults();
              }
            });
        }
      });
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
